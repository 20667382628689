<template>
  <!--  <div>-->
  <!--    <div class="surroundContainer">-->
  <!--      <div class="header">-->
  <!--        <div class="icon"></div>-->
  <!--        <div class="title">{{ description }}</div>-->
  <!--      </div>-->
  <!--      <div class="chart-box">-->
  <!--        <div class="tag-box" id="tag-box">-->
  <!--          <div class="beg test"></div>-->
  <!--          <div class="beg center-beg">-->
  <!--&lt;!&ndash;            <span class="text"> 生物有机肥</span>&ndash;&gt;-->
  <!--          </div>-->

  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->

  <surroundBox :name="description" desc="废弃物加工后得到的产品">
    <template slot="content">
      <div class="chart-box-1">
        <div id="tag-box" class="tag-box">
          <div class="beg test"></div>
          <div class="beg center-beg">
          </div>
        </div>
      </div>
    </template>
  </surroundBox>


</template>

<script>
import surroundBox from "../surroundBox/surroundBox";

export default {
  components: {
    surroundBox,
  },
  name: "recyclingProductForm",
  data() {
    return {
      description: "资源化产品形态",
      dataSource: [],
    }
  },
  mounted() {
    this.loadData().then(() => {
      this.ballCreate()
    });


  },
  methods: {
    loadData() {
      this.datas = []
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 180
      }).then(res => {
        if (res && res.length > 0) {
          this.dataSource = res.map(item => item.content)
        }
      })
    },
    ballCreate() {
      var oC = document.getElementById('tag-box')
      let begWidth = document.querySelector('.beg.test').clientWidth
      let centerBegWidth = document.querySelector('.center-beg').clientWidth
      var mxwidth = oC.offsetWidth
      var mxheight = oC.offsetHeight
      let oB = new CollBox('tag-box', {width: mxwidth, height: mxheight})
      oB.ballRun()
      for (let i = 1; i <= this.dataSource.length - 1; i++) {
        let item = this.dataSource[i]
        var b = rand(begWidth / 2, centerBegWidth / 2)
        var x = rand(b, mxwidth - b)
        var y = rand(b, mxheight - b)

        let ball = new Ball({
          b: b,
          'x': x,
          'y': y,
          'sx': 0.8,
          'sy': 0.8,
          'html': `<div style="cursor:pointer;width: 100%;height: 100%;border-radius: 50%;justify-content: center; text-align: center;box-shadow: inset 0 0 8px 0 #31FFF1;display: flex;align-items: center;">
<span style="color: #DDDDDD;font-size: 0.12rem;font-weight: 500;">${item}</span>
</div>`

        })
        oB.addBall(ball)
      }

      function rand(m, n) {
        return m + parseInt((n - m) * Math.random())
      }


    },
    // 初始化每个小的位置
    initLocation() {
      // 获取一个正常求的大小
      let begWidth = document.querySelector('.beg.test').clientWidth
      let centerBeg = document.querySelector('.center-beg')
      // 获取中心球的宽高 由于市圆形 宽高一样 获取一个就行
      let centerBegWidth = centerBeg.clientWidth
      // 拿到中心这个的top,left
      let centerTop = centerBeg.offsetTop
      let centerLeft = centerBeg.offsetLeft
      // 及计算顺时针每个小元素应该的位置
      // 第一个的位置
      let beg1 = {
        top: centerTop - begWidth,
        left: centerLeft - (centerLeft - begWidth) / 2
      }
      // 知道第一个就知道底部这个 也就是第4个
      let beg4 = {
        top: centerTop + centerBegWidth,
        left: centerLeft - (centerLeft - begWidth) / 2
      }
      // 第二个
      let beg2 = {
        top: centerTop - begWidth,
        left: beg1.left + 19
      }
      // 第三个
      let beg3 = {
        top: centerTop - begWidth,
        left: beg2.left
      }


      this.offset = [beg1, beg4]
    }
  }
}
</script>

<style lang="less" scoped>
.chart-box-1 {
  width: 100%;
  height: 100%;
}

.tag-box {
  width: 100%;
  height: calc(100% - 1vh);
  position: relative;

  .beg {
    width: 0.69rem;
    height: 0.69rem;
    border-radius: 50%;
    background-color: transparent;
    padding: 0 0.1rem;
    text-align: center;
    box-shadow: inset 0 0 8px 0 #31FFF1;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s;

    .text {
      color: #DDDDDD;
      font-size: 0.12rem;
      font-weight: 500;
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  .beg.test {
    position: absolute;
    opacity: 0;
  }

  .center-beg {
    opacity: 0;
    width: 0.91rem;
    height: 0.91rem;
    padding: 0 0.23rem;
    position: absolute;
    top: calc(50% - 0.455rem);
    left: calc(50% - 0.455rem);

    .text {
      opacity: 0;
      font-size: 0.15rem;
    }
  }
}
</style>
